<template>
  <section class="card">
    <data-header :handleTrocarVisao="handleTrocarVisao"
                 :filtros="filtros"
                 :itemSelecionado="itemSelecionado"
                 :actionButtons="actionButtons">
      <template slot="header">
        <span class="selecionado" v-html="selecionado">
        </span>
      </template>
    </data-header>
    <div class="row main-row">
      <filtro-component :filtros="filtros"
                        :params="params"
                        :handleConsultar="handleConsultar">
      </filtro-component>
      <data-table :result="result"
                  :params="params"
                  :handleRowClick="handleRowClick"
                  :checkboxClass="resolveCheckboxClass"
                  :itemSelecionado="itemSelecionado[0]"
                  :resolveStatusClass="resolveStatusClass"
                  :handleOrdenar="handleOrdenar"
                  :selected="selected"
                  :filtros="filtros"
                  :handleConsultar="handleConsultar">
      </data-table>
    </div>
  </section>

</template>

<script>
import api from '@/services/api.js';
import consultar from '@/services/consultar.js';
import { statusFatura } from '@/services/enums.js';

//usuarios
export default {

    components: {
        ...consultar.components,

    },
    data() {
        const that = this;
        return {
            ...consultar.data(),
            url: '/configuracoes/faturas',
            clone: '',
            obj: {},
            actionButtons: [
                {
                    text: 'Gerar Boleto',
                    className: 'editar padrao',
                    icon: 'fas fa-money-check-alt',
                    color: 'default',
                    visible: true,// inicial
                    visibleOnRowSelected: true,
                    title: 'Gerar boleto da fatura selecionada',
                    callback: that.handleGerarFaturaButton
                },
                {
                    text: 'Visualizar Boleto',
                    className: 'editar padrao',
                    icon: 'fas fa-eye',
                    color: 'default',
                    visible: true,// inicial
                    visibleOnRowSelected: true,
                    title: 'Visualizar boleto de fatura selecionada',
                    callback: that.handleVisualizarBoletoButton
                }
            ],
            filtros:
            {
                data: [
                    {
                        id: 'Id',
                        title: 'C�digo',
                        active: false
                    },
                    {
                        id: 'DataVencimento',
                        title: 'Data Vencimento',
                        active: false,
                        placeholder: '',
                        type: 'date'
                    },
                    {
                        id: 'StatusCobranca',
                        title: 'Status',
                        active: false,
                        value: [],
                        source: [
                            {
                                id: 'Criada',
                                title: 'Criada',
                                value: 0
                            },
                            {
                                id: 'Aberta',
                                title: 'Aberta',
                                value: 1
                            },
                            {
                                id: 'Pago',
                                title: 'Pago',
                                value: 2
                            },
                            {
                                id: 'Cortesia',
                                title: 'Cortesia',
                                value: 3
                            },
                            {
                                id: 'Erro',
                                title: 'Erro',
                                value: 4
                            }
                        ],
                        sourceType: 'checkbox'
                    }
                ],
                visao: [
                    {
                        id: 0,
                        title: 'Todas faturas',
                        filtro: []
                    },
                    {
                        id: 1,
                        title: 'Faturas em aberto',
                        filtro:
                            [
                                {
                                    id: 'StatusFatura',
                                    value: [0,1],
                                },
                            ]
                    }
                ],
                visaoSelecionada: 0,
                visible: true
            },
            statusFatura: statusFatura,
        };
    },

    methods: {
        ...consultar.methods,
        handleGerarFaturaButton: function () {
            alert('fatura gerada');
        },
        handleVisualizarBoletoButton() {
            window.open(this.itemSelecionado[0].BoletoLink, '_blank');
        },
        reset: function () {
            let that = this;
            api.post('/configuracoes/resetarsenhausuario', { id: this.itemSelecionado[0].Id })
                .then(response => {
                    console.log(response.data);
                    if (response.data.success) {
                        that.modals.show = false;
                        that.itemSelecionado.pop();
                        that.selected.pop();
                    }
                    else {
                        that.$root.errors = response.data.errors;
                    }
                });
        },
        callback: function (request) {
           
        },
        salvar: function () {
            this.$root.errors = [];
            this.itemSelecionado[0].Login = this.itemSelecionado[0].Pessoa.Email;
            api
                .post(this.url, this.itemSelecionado[0])
                .then(response => {
                    console.log(response.data);
                    if (response.data.success) {

                        //busco id do item que retornou no array
                        let x = this.result.Records.filter(i => i.Id === response.data.obj.Id);
                        //se encontreim substituo o item
                        if (x.length) {
                            this.result.Records.splice(this.result.Records.indexOf(x[0]), 1, response.data.obj);
                        }
                        //se n�o encontrei, insiro ele na primeira posi��o
                        else {
                            this.result.Records.splice(0, 0, response.data.obj);
                        }
                        this.modals.show = false;
                        this.itemSelecionado.pop();
                        this.selected.pop();
                        this.changeCheckboxCallback();
                    }
                    else {
                        this.$root.errors = response.data.errors;
                    }
                });

        },
        resolveStatusClass: function (name) {
            switch (name) {
                case 'Criada':
                    return 'info';
                case 'Aberto':
                    return 'warning';
                case 'Pago':
                    return 'success';
                case 'Cortesia':
                    return 'muted';
                default:
                    return 'danger';
            }
        },
        changeCheckboxCallback: function (item) {

            this.selecionado = '';
           
            let btnBoleto = this.actionButtons.filter(b => b.text === 'Gerar Boleto')[0];
            let btnVisualizar = this.actionButtons.filter(b => b.text === 'Visualizar Boleto')[0];

            btnBoleto.visible = false;
            btnVisualizar.visible = false;

            if (item) {
                this.selecionado = `Fatura ${item.Id} | * ${item.ValorDisplay} *`;
                if (item.StatusCobranca === 0 || item.StatusCobranca === 4) {
                    btnBoleto.visible = true;
                }
                else if (item.StatusCobranca === 1) {
                    btnVisualizar.visible = true;
                }
            }
            
        },



    },
    mounted: consultar.mounted,


    beforeMount: function () {
    }
};</script>
